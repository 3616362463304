import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import styles from "./Card.module.scss";

const cx = classNames.bind(styles);

function Card({
    content = "",
    title,
    path,
    image = [],
    scope,
    location,
    large = false,
    small = false
}) {
    return (
        <Link to={path} className={cx('card', { large, small})}>
            <div className={cx('img-container')}><img className={cx('img')} src={image[0]} alt="Image" /></div>
            <h3 className={cx('header')}>{(title.length > 40) ? (title.slice(0, 30) + "...") : title}</h3>
            <div className={cx('footer')}>
                {content && <div>{(content.length > 220) ? (content.slice(0, 220) + " ...") : content}</div>}
                {scope && <p className={cx('footer-text')}><span className={cx('primary-text')}>Phạm vi công việc: </span>{scope}</p>}
                {location && <p className={cx('footer-text')}><span className={cx('primary-text')}>Địa điểm: </span>{location}</p>}
            </div>
        </Link>
    )
}

export default Card;