import { Fragment } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./Services.module.scss"
import { Row, Col } from "react-bootstrap";

import Navbar from "~/components/Navbar";
import InfoService from "./InfoService";
import Heading from "~/components/Heading";
// import Element from "~/pages/Projects/Element";

const cx = classNames.bind(styles);

function Services() {
    return (
        <Fragment>
            <Navbar active="services" />
            <div className="container">
                <Row>
                    <Heading content="Các lĩnh vực hoạt động" />
                    <Col lg={4}>
                        <div className={cx('item')}>
                            <h3>Thiết kế xây dựng</h3>
                            <Link className={cx('btn')} to="/projects"><span className="btn-home">Xem dự án</span></Link>
                        </div>
                        <div className={cx('item')}>
                            <h3>Quản lý dự án và Giám Sát thi công</h3>
                            <Link className={cx('btn')} to="/projects"><span className="btn-home">Xem dự án</span></Link>
                        </div>
                        <div className={cx('item')}>
                            <h3>Tư vấn pháp lý</h3>
                            <Link className={cx('btn')} to="/projects"><span className="btn-home">Xem dự án</span></Link>
                        </div>
                        {/* <div className={cx('item')}>
                            <h3>Thi công xây dựng</h3>
                            <Link className={cx('btn')} to="/projects/constructions"><span className="btn-home">Xem dự án</span></Link>
                        </div> */}
                    </Col>
                    <Col lg={8}>
                        <InfoService />
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
}

export default Services;