import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "~/pages/Services/Services.module.scss";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

const cx = classNames.bind(styles)

function InfoService() {
    const services = [
        "Lập dự án đầu tư",
        "Tư vấn thiết kế công trình Quy hoạch, dân dụng và công nghiệp",
        "Tư vấn Pháp lý dự án",
        "Tư vấn đấu thầu",
        "Khảo sát xây dựng; thí nghiệm; quy hoạch; thiết kế",
        "Thẩm định dự án đầu tư; thẩm tra thiết kế; tổng dự toán; kiểm định chất lượng",
        "Giám sát thi công; quản lý dự án; xây dựng thực nghiệm",
        "Trang trí nội ngoại thất và các dịch vụ tư vấn khác.",
    ]
    return (
        <>
            <h4 className={cx("text-large")}>
                <FontAwesomeIcon className="right-icon" icon={faCaretRight} />
                Tổng thầu tư vấn và quản lý các dự án xây dựng.
            </h4>
            <h4 className={cx("text-large")}>
                <FontAwesomeIcon className="right-icon" icon={faCaretRight} />
                Tư vấn xây dựng các khu dân cư, khu đô thị, khu công nghiệp và các công trình dân dụng, công nghiệp, nông nghiệp, thủy sản, lâm nghiệp, giao thông, thủy lợi, thông tin bưu điện, đường dây và trạm biến thế điện, chiếu sáng, cấp thoát nước và các công trình kỹ thuật hạ tầng bao gồm:
            </h4>
            <div className={cx('description')}>
                {services.map(content => {
                    return <p className={cx("text")}>+ {content}</p>
                })}
            </div>
            <h4 className={cx("text-large")}>
                <FontAwesomeIcon className="right-icon" icon={faCaretRight} />
                Tổng thầu xây dựng các công trình dân dụng, công nghiệp và hạ tầng kỹ thuật
            </h4>
        </>
    );
}

export default InfoService;