import { Fragment } from "react";
import { Carousel } from "react-responsive-carousel";
import classNames from "classnames/bind";
import styles from "~/pages/Home/Home.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const cx = classNames.bind(styles);

function Slider({ interval = 2000,
    items = [],
    showArrows = true,
    centerSlidePercentage = 60,
    height = "72vh",
    objectFit = "cover",
    showIndicators = false
}) {
    const customImage = {
        height: height,
        objectFit: objectFit
    }
    
    return (
        <Fragment>
            <Carousel infiniteLoop autoPlay interval={interval}
                showStatus={false} showIndicators={showIndicators} showThumbs={false}
                centerMode={true}
                showArrows={showArrows}
                centerSlidePercentage={centerSlidePercentage}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <button className={cx("arrow-btn")} type="button" onClick={onClickHandler} title={label} >
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <button className={cx("arrow-btn")} type="button" onClick={onClickHandler} title={label} >
                            <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                    )
                }>
                {
                    items.map((image, index) => {
                        return <img key={index} style={customImage} src={image} alt="Hình ảnh" />
                    })
                }
            </Carousel>
        </Fragment>
    );
}

export default Slider;