import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Topbar from "~/components/Topbar";
import Footer from "~/components/Footer";
import Element from "~/pages/Projects/Element";
import Projects from "~/pages/Projects";
import Home from "~/pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Single from "./pages/Single";
import NotFound404 from "./components/NotFound404";

function App() {
  return (
    <Router>
      <Topbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />}>
          <Route path="" element={<Element type="all_projects" />} />
          <Route path=":id" element={<Single />} />
          <Route path="civil_projects" element={<Element type="civil_projects" />} />
          <Route path="ifs_projects" element={<Element type="ifs_projects" />} />
          <Route path="ind_projects" element={<Element type="ind_projects" />} />
          <Route path="constructions" element={<Element type="constructions" />} />
          <Route path="*" element={<NotFound404 />} />
        </Route>
        <Route path="/services" element={<Services />} >
          <Route path="design" element={<Services />} />
          <Route path="construction" element={<Services />} />
          <Route path="consultant" element={<Services />} />
          <Route path="management" element={<Services />} />
          <Route path="*" element={<NotFound404 />} />
        </Route>
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router >
  );
}

export default App;