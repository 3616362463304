import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { Row, Col } from "react-bootstrap"

import styles from "./Contact.module.scss";
import Navbar from "~/components/Navbar";
import Heading from "~/components/Heading";

const cx = classNames.bind(styles);

function Contact() {
    return (
        <>
            <Navbar active="contact" />
            <div className="container">
                <Row>
                    <Heading content="Thông tin liên hệ" />
                    <Col lg={6}>

                        <div className={cx('item')}>
                            <h3 className={cx('heading')}><FontAwesomeIcon className={cx('icon')} icon={faBuilding} />Địa chỉ văn phòng</h3>
                            <p className={cx('content')}>82 Hoa Lan, Phường 2, Phú Nhuận, TP. Hồ Chí Minh</p>
                        </div>
                        <div className={cx('item')}>
                            <h3 className={cx('heading')}><FontAwesomeIcon className={cx('icon')} icon={faEnvelope} />Email</h3>
                            <p className={cx('content')}>info @trivietcc.com</p>
                        </div>
                        <div className={cx('item')}>
                            <h3 className={cx('heading')}><FontAwesomeIcon className={cx('icon')} icon={faPhoneAlt} />Điện thoại</h3>
                            <p className={cx('content')}>02866.825.791</p>
                        </div>
                        <div className={cx('item')}>
                            <h3 className={cx('heading')}><FontAwesomeIcon className={cx('icon')} icon={faFacebook} />Facebook</h3>
                            <a className={cx('link')} target="_blank" rel="noreferrer" href="https://www.facebook.com/Tr%C3%AD-Vi%E1%BB%87t-Engineering-100218329324885">Trí Việt
                                Engineering</a>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <iframe className={cx('map')}
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.1840431557835!2d106.68774405111051!3d10.797211992269697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317528ce4f5572b1%3A0xa41b9debfd3c97ae!2zODIgSG9hIExhbiwgUGjGsOG7nW5nIDcsIFBow7ogTmh14bqtbiwgVGjDoG5oIHBo4buRIEjhu5MgQ2jDrSBNaW5oLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1654829962989!5m2!1sen!2s"
                            style={{ width: "100%", height: "100%" }} allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade" title="Map" />
                    </Col>
                </Row>
            </div >
        </ >
    );
}

export default Contact;