import React, { useEffect, useState } from 'react';
import Items from './Items';
import ReactPaginate from 'react-paginate';
import { civil_projects, ifs_projects, ind_projects, all_projects, main_projects, constructions } from "~/projects"

function Pagination({ itemsPerPage, type = "", small = false }) {
    let items = []
    switch (type) {
        case "civil_projects": items = civil_projects;;
            break;
        case "ifs_projects": items = ifs_projects;
            break;
        case "ind_projects": items = ind_projects;
            break;
        case "main_projects": items = main_projects;
            break;
        case "constructions": items = constructions;
            break;
        default: items = all_projects;
            break;
    }

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    console.log(currentItems);

    useEffect(() => {
        console.log('pagination re-render');
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, type]);



    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % items.length;
        setItemOffset(newOffset);
    };

    return (
        <>
            <Items currentItems={currentItems} small={small} />
            <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </>
    );
}

export default Pagination;