import { Fragment } from "react";
import Navbar from "~/components/Navbar";
import { Outlet } from "react-router";

function Projects() {
    return (
        <Fragment>
            <Navbar active="projects" />
            <div className="container">
                <Outlet />
            </div>
        </Fragment>
    );
}

export default Projects;