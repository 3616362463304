import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import styles from "./Info.module.scss";

const cx = classNames.bind(styles);

function Info() {
    return (
        <div>
            <h3 class={cx('heading')}>SỰ HÌNH THÀNH VÀ PHÁT TRIỂN</h3>
            <p><FontAwesomeIcon className="right-icon" icon={faCaretRight} />Năm 2016, từ một nhóm kỹ sư chuyên thiết kế, thi công và giám sát các công trình xây dựng. Với hơn mười năm tích lũy kinh nghiệm qua việc tham gia thực hiện nhiều công trình xây dựng lớn nhỏ, CÔNG TY CỔ PHẦN TƯ VẤN KỸ NGHỆ TRÍ VIỆT ra đời với sự nhất trí cao của Hội đồng, là những người cộng tác lâu năm với nhau.</p>
            <p><FontAwesomeIcon className="right-icon" icon={faCaretRight} />Chúng tôi tự hào là đội ngũ có nhiều năm kinh nghiệm với nghề, sẵn sàng đáp ứng với mọi nhu cầu khách hàng, để hoàn thành những công trình chất lượng.</p>

            <h3 class={cx('heading')}>CƠ CẤU TỔ CHỨC CÔNG TY </h3>
            <p><FontAwesomeIcon className="right-icon" icon={faCaretRight} />Công Ty Cổ Phần Tư vấn Kỹ nghệ Trí Việt hoạt động theo mô hình công ty cổ phần có sự giám sát của hội đồng thành viên.</p>
            <p><FontAwesomeIcon className="right-icon" icon={faCaretRight} />Ban điều hành công ty bao gồm Giám Đốc và trưởng các bộ phận trực thuộc.</p>
            <p><FontAwesomeIcon className="right-icon" icon={faCaretRight} />Giám đốc công ty là người đại diện và chịu trách nhiệm cao nhất của công ty trước pháp luật, trước hội đồng thành viên, khách hàng, nhà cung cấp, thầu phụ về mọi hoạt động sản xuất kinh doanh của công ty.</p>
            <h3 class={cx('heading')}>TRIẾT LÝ KINH DOANH</h3>

            <h4 className={cx('slogan')}>VỚI PHƯƠNG CHÂM:  UY TÍN – SÁNG TẠO – HIỆU QUẢ</h4>
            <p><FontAwesomeIcon className="right-icon" icon={faCaretRight} />TRÍ VIỆT Luôn luôn mang phương châm này đến tất cả các dự án đã và đang thực hiện.</p>
            <p><FontAwesomeIcon className="right-icon" icon={faCaretRight} />TRÍ VIỆT Tự hào là một trong những nhà tư vấn UY TÍN tại Việt Nam – Một người bạn đồng hành quen thuộc của các chủ Đầu tư trong nước cũng như Quốc tế.</p>
            <p><FontAwesomeIcon className="right-icon" icon={faCaretRight} />TRÍ VIỆT Ngày càng nhận được sự tín nhiệm của các Chủ đầu tư trong nước cũng như nước ngoài, trở thành một trong các Công ty tư vấn quen thuộc và đáng tin cậy của các nhà đầu tư cũng như các nhà thầu trong nước và quốc tế tại Việt Nam.</p>
            <p><FontAwesomeIcon className="right-icon" icon={faCaretRight} />TRÍ VIỆT Gửi đến các nhà Đầu tư thương hiệu này, thay cho lời đảm bảo về tính Bền Vững – Thẩm Mỹ – Kinh Tế – Hữu Dụng đối với tất cả các loại công trình.</p>
        </div>
    );
}

export default Info;