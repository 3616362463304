export const civil_projects = [
    {
        id: "civil_1",
        name: "CELADON CITY – PLOT A3",
        scope: "Thiết kế hạ tầng đường D6, Thiết kế Kết cấu và Mep cho 51 căn hộ",
        location: "Quận Tân Phú, Tp. Hồ Chí Minh",
        image: [
            "/images/civil-project-1.jpg"
        ]
    },
    {
        id: "civil_2",
        name: "TÒA NHÀ VĂN PHÒNG CHO THUÊ HÒA NAM – 11 TẦNG",
        scope: "Tư vấn Thiết kế",
        location: "Quận 3, Tp. Hồ Chí Minh",
        image: [
            "/images/civil-project-2.jpg"
        ]
    },
    // {
    //     id: "civil_3",
    //     name: "CHUNG CƯ ĐÔNG GÒ CÁT",
    //     scope: "Thiết kế Hệ thống cơ điện",
    //     location: "Quận 9, Tp. Hồ Chí Minh",
    //     image: [
    //         "/images/civil-project-3a.jpg",
    //         "/images/civil-project-3b.jpg"
    //     ]
    // },
    // {
    //     id: "civil_4",
    //     name: "CHUNG CƯ MINH QUỐC",
    //     scope: "Tư vấn Thiết kế cơ điện",
    //     location: "Tỉnh Bình Dương",
    //     image: [
    //         "/images/civil-project-4a.jpg",
    //         "/images/civil-project-4b.jpg"
    //     ]
    // },
    {
        id: "civil_5",
        name: "VĂN PHÒNG 32 PHẠM NGỌC THẠCH",
        scope: "Quản lý dự án & Tư vấn giám sát",
        location: "Quận 3 Tp. Hồ Chí Minh",
        image: [
            "/images/civil-project-5.jpg"
        ]
    },
    {
        id: "civil_6",
        name: "NHÀ HÀNG NGỌC SƯƠNG",
        scope: "Tư vấn Thiết kế cơ điện",
        location: "Quận 1, Tp. Hồ Chí Minh",
        image: [
            "/images/civil-project-6.jpg"
        ]
    },
    {
        id: "civil_7",
        name: "ÒA NHÀ VĂN PHÒNG CHO THUÊ KẾT HỢP NHÀ Ở - HÀ QUANG",
        scope: "Tư vấn Thiết kế",
        location: "Quận 2, Tp. Hồ Chí Minh",
        image: [
            "/images/civil-project-7.jpg"
        ]
    },
    {
        id: "civil_8",
        name: "SIÊU THỊ ĐIỆN MÁY NGUYỄN KIM - THỪA THIÊN HUẾ",
        scope: "Tư vấn Thiết kế",
        location: "Tp.Huế, tỉnh Thừa Thiên Huế",
        image: [
            "/images/civil-project-8.jpg"
        ]
    },
    {
        id: "civil_9",
        name: "SIÊU THỊ ĐIỆN MÁY NGUYỄN KIM - TÂY NINH",
        scope: "Tư vấn Thiết kế",
        location: "Tp. Tây Ninh, tỉnh Tây Ninh",
        image: [
            "/images/civil-project-9.jpg"
        ]
    },
    {
        id: "civil_10",
        name: "TRUNG TÂM THƯƠNG MẠI BIGC BÀ RỊA",
        scope: "Thiết kế cơ điện",
        location: "Tỉnh Bà Rịa – Vũng Tàu",
        image: [
            "/images/civil-project-10.jpg"
        ]
    },
    {
        id: "civil_11",
        name: "TRUNG TÂM THƯƠNG MẠI BIGC BẾN TRE",
        scope: "Tư vấn Thiết kế cơ điện",
        location: "Tỉnh Bến Tre",
        image: [
            "/images/civil-project-11.jpg"
        ]
    },
    // {
    //     id: "civil_12",
    //     name: "TRƯỜNG MẦM NON LOVELY KIDS",
    //     scope: "Thiết kế ",
    //     location: "Quận 7, TP.HCM",
    //     image: [
    //         "/images/civil-project-12a.jpg",
    //         "/images/civil-project-12b.jpg"
    //     ]
    // },
    {
        id: "civil_13",
        name: "TRƯỜNG MẦM NON  CANADA – VIỆT NAM",
        scope: "Tư vấn giám sát",
        location: "Quận 7, TP. HCM",
        image: [
            "/images/civil-project-13a.jpg",
            "/images/civil-project-13b.jpg"
        ]
    },
    {
        id: "civil_14",
        name: "TRƯỜNG MẦM NON QUỐC TẾ BẢO QUYÊN BẾN TRE",
        scope: "Thành phố Bến Tre, tỉnh Bến Tre",
        location: "Tư Vấn Thiết kế",
        image: [
            "/images/civil-project-14a.jpg",
            "/images/civil-project-14b.jpg",
            "/images/civil-project-14c.jpg",
            "/images/civil-project-14d.jpg",
            "/images/civil-project-14e.jpg"
        ]
    },
    // {
    //     id: "civil_15",
    //     name: "chung cư victoria garden",
    //     scope: "tư vấn thiết kế",
    //     location: "quận bình chánh, tp. hồ chí minh",
    //     image: [
    //         "/images/civil-project-15.jpg"
    //     ]
    // },
    // {
    //     id: "civil_16",
    //     name: "chung cư bella plaza",
    //     scope: "tư vấn thiết kế",
    //     location: "biên hòa, đồng nai",
    //     image: [
    //         "/images/civil-project-16a.jpg",
    //         "/images/civil-project-16b.jpg"
    //     ]
    // },
    // {
    //     id: "civil_17",
    //     name: "khu dân cư tân cửu nghĩa",
    //     scope: "tư vấn thiết kế",
    //     location: "châu thành, tiền giang",
    //     image: [
    //         "/images/civil-project-17a.jpg",
    //         "/images/civil-project-17b.jpg",
    //         "/images/civil-project-17c.jpg"
    //     ]
    // },
    // {
    //     id: "civil_18",
    //     name: "khu vui chơi & nghỉ dưỡng vịnh đầm phú quốc–kiên giang",
    //     scope: "tư vấn thiết kế",
    //     location: "phú quốc, tỉnh kiên giang",
    //     image: [
    //         "/images/civil-project-18a.jpg",
    //         "/images/civil-project-18b.jpg",
    //         "/images/civil-project-18c.jpg",
    //         "/images/civil-project-18d.jpg",
    //         "/images/civil-project-18e.jpg"
    //     ]
    // }
].sort(() => Math.random() - 0.5);

export const ifs_projects = [
    {
        id: "ifs_1",
        name: "CÔNG VIÊN THẾ GIỚI DI SẢN KIẾN TRÚC – THE DESTINA",
        scope: "Tư vấn Thiết kế hạ tầng và công viên cây xanh",
        location: "Tp Đồng Xoài, tỉnh Bình Phước",
        image: [
            "/images/ifs-project-1.jpg"
        ]
    },
    {
        id: "ifs_2",
        name: "NHÀ ĐIỀU HÀNH",
        scope: "Tư vấn Thiết kế",
        location: "Đồng Xoài, Bình Phước",
        image: [
            "/images/ifs-project-2.jpg"
        ]
    },
    // {
    //     id: "ifs_3",
    //     name: "CÁT TƯỜNG THIÊN PHÚC BÌNH DƯƠNG",
    //     scope: "Thiết kế hạ tầng",
    //     location: "Phường Bình Chuẩn, thị xã Thuận An, Bình Dương",
    //     image: [
    //         "/images/ifs-project-3.jpg"
    //     ]
    // },
    {
        id: "ifs_4",
        name: "CÁT TƯỜNG HẬU GIANG",
        scope: "Thiết kế hạ tầng",
        location: "Tỉnh Hậu Giang",
        image: [
            "/images/ifs-project-4.jpg"
        ]
    },
    {
        id: "ifs_5",
        name: "TỔ HỢP KHU PHỨC HỢP ZONE 6 ĐẠI PHƯỚC",
        scope: "Thẩm tra Thiết kế ",
        location: "Tỉnh Đồng Nai",
        image: [
            "/images/ifs-project-5.jpg"
        ]
    },
    {
        id: "ifs_6",
        name: "CÔNG VIÊN ZONE 6 ĐẠI PHƯỚC",
        scope: "Thiết kế công viên",
        location: "Tỉnh Đồng Nai",
        image: [
            "/images/ifs-project-6.jpg"
        ]
    },
    {
        id: "ifs_7",
        name: "KHU NHÀ Ở PHÁT DẠT NHƠN HỘI - ZONE 9",
        scope: "Thẩm tra Hạ Tầng",
        location: "Khu kinh tế Nhơn Hội, Quy Nhơn, Bình Định",
        image: [
            "/images/ifs-project-7.jpg"
        ]
    },
    {
        id: "ifs_8",
        name: "KHU NHÀ Ở PHÁT ĐẠT BẮC HÀ THANH",
        scope: "Thẩm tra Hạ Tầng",
        location: "Tuy Phước, Bình Định",
        image: [
            "/images/ifs-project-8.jpg"
        ]
    },
    {
        id: "ifs_9",
        name: "CỤM CÔNG NGHIỆP THANH TÂN – TIỀN GIANG",
        scope: "Thiết kế Hạ Tầng",
        location: "Tân Phước, Tiền Giang",
        image: [
            "/images/ifs-project-9.jpg"
        ]
    },
    {
        id: "ifs_10",
        name: "CỤM CÔNG NGHIỆP ĐÔNG HÀ- BÌNH THUẬN",
        scope: "Thiết kế Quy Hoạch, Hạ Tầng",
        location: "Đông Hà, Bình Thuận",
        image: [
            "/images/ifs-project-10.jpg"
        ]
    },
    {
        id: "ifs_11",
        name: "KHU NHÀ Ở VƯỜN XUÂN (LAVIDA)",
        scope: "Tư vấn thiết kế hạ tầng",
        location: "Tp. Vũng Tàu, tỉnh Bà Rịa-Vũng Tàu",
        image: [
            "/images/ifs-project-11a.jpg",
            "/images/ifs-project-11b.jpg"
        ],
        area: "30ha"
    },
    {
        id: "ifs_12",
        name: "KHU PHỨC HỢP THÁP QUAN SÁT (THUỘC KHU CHỨC NĂNG SỐ 2B)",
        scope: "Tư vấn thiết kế hạ tầng",
        location: "Quận 2, Tp. HCM",
        image: [
            "/images/ifs-project-12.jpg"
        ],
        area: "14.5ha"
    },
    {
        id: "ifs_13",
        name: "KHU ĐÔ THỊ SINH THÁI ĐẠI PHƯỚC (PHÂN KHU 4, PHÂN KHU 6)",
        scope: "Tư vấn Quy hoạch, thẩm tra thiết kế, tư vấn pháp lý dự án",
        location: "Huyện Nhơn Trạch, tỉnh Đồng Nai",
        image: [
            "/images/ifs-project-13.jpg"
        ],
        area: "200ha"
    },
    {
        id: "ifs_14",
        name: "KHU HẬU CẦN DU LỊCH – NGHỈ DƯỠNG CAO CẤP HÒN DẤU",
        scope: "Tư vấn thiết kế",
        location: "Quận Đồ Sơn, Tp. Hải Phòng",
        image: [
            "/images/ifs-project-14.jpg"
        ],
        are: "20ha"
    },
    {
        id: "ifs_15",
        name: "KHU DÂN CƯ VÀ DU LỊCH – VĂN HÓA – GIẢI TRÍ THUỘC KHU DÂN CƯ NAM RẠCH CHIẾC - LAKEVIEW CITY",
        scope: "Tư vấn thiết kế, tư vấn pháp lý dự án",
        location: "Tp. Thủ Đức, Tp. Hồ Chí Minh",
        image: [
            "/images/ifs-project-15.jpg"
        ]
    },
    {
        id: "ifs_16",
        name: "KHU ĐÔ THỊ HIM LAM",
        scope: "Tư vấn thiết kế",
        location: "Tp.Bắc Ninh, Tỉnh Bắc Ninh",
        image: [
            "/images/ifs-project-16.jpg"
        ],
        area: "26.8ha"
    },
    {
        id: "ifs_17",
        name: "KHU NHÀ Ở HIM LAM VĨNH TUY",
        scope: "Tư vấn thiết kế",
        location: "Quận Long Biên, Tp. Hà Nội",
        image: [
            "/images/ifs-project-17.jpg"
        ]
    },
].sort(() => Math.random() - 0.5)

export const ind_projects = [
    {
        id: "ind_1",
        name: "NHÀ MÁY BIGRFEED BÌNH ĐỊNH",
        scope: "Tư vấn Thiết kế",
        location: "huyện An Nhơn, tỉnh Bình Định",
        image: [
            "/images/ind-project-1.jpg"
        ]
    },
    {
        id: "ind_2",
        name: "NHÀ MÁY TAKUMINO BÌNH ĐỊNH",
        scope: "Thẩm tra Thiết kế",
        location: "huyện An Nhơn, tỉnh Bình Định",
        image: [
            "/images/ind-project-2.jpg"
        ]
    },
    // {
    //     id: "ind_3",
    //     name: "NHÀ MÁY DYNAPLAST ĐỒNG NAI",
    //     scope: "Tư vấn thiết kế và XPXD, môi trường, PCCC",
    //     location: "Biên Hòa, Đồng Nai",
    //     image: [
    //         "/images/ind-project-3.jpg"
    //     ]
    // },
    {
        id: "ind_4",
        name: "NHÀ MÁY THỨC ĂN CHĂN NUÔI VIỆT THẮNG LONG AN",
        scope: "Tư vấn thiết kế và XPXD, môi trường, PCCC",
        location: "Bến Lức, Long An",
        image: [
            "/images/ind-project-4.jpg"
        ]
    },
    // {
    //     id: "ind_5",
    //     name: "NHÀ MÁY CHẾ BIẾN THỨC ĂN CHĂN NUÔI DEHEUS ĐỒNG NAI MỞ RỘNG",
    //     scope: "Thiết kế, XPXD, môi trường, PCCC và hoàn công",
    //     location: "KCN Dầu Giây, Đồng Nai ",
    //     image: [
    //         "/images/ind-project-5.jpg"
    //     ]
    // },
    {
        id: "ind_6",
        name: "NHÀ MÁY THỨC ĂN CHĂN NUÔI CJ VINA AGRI – CN BÌNH ĐỊNH",
        scope: "Thiết kế ,XPXD, môi trường, PCCC và hoàn công",
        location: "KCN Nhơn Hòa – Bình Định",
        image: [
            "/images/ind-project-6.jpg"
        ]
    },
    {
        id: "ind_7",
        name: "NHÀ MÁY THỨC ĂN CHĂN NUÔI CJ VINA AGRI – CN VĨNH LONG",
        scope: "Tư vấn, thiết kế",
        location: "Bình Minh, Vĩnh Long",
        image: [
            "/images/ind-project-7.jpg"
        ]
    },
    // {
    //     id: "ind_8",
    //     name: "NHÀ MÁY KIỀM NGHĨA CỦ CHI",
    //     scope: "Tư vấn thiết kế",
    //     location: "KCN Tân Phú Trung, Củ Chi, HCM",
    //     image: [
    //         "/images/ind-project-8.jpg"
    //     ]
    // },
    {
        id: "ind_9",
        name: "NHÀ MÁY TRỨNG GÀ SẠCH PHÚ THỌ",
        scope: "Tư vấn thiết kế",
        location: "Tam Nông, Phú Thọ",
        image: [
            "/images/ind-project-9.jpg"
        ]
    },
    // {
    //     id: "ind_10",
    //     name: "NHÀ MÁY ẤP TRỨNG GIA CẦM MINH DƯ",
    //     scope: "Thẩm tra dự án",
    //     location: "Huyện Tuy Phước, tỉnh Bình Định",
    //     image: [
    //         "/images/ind-project-10.jpg"
    //     ]
    // },
    {
        id: "ind_11",
        name: "NHÀ MÁY THỨC ĂN CHĂN NUÔI DABACO BÌNH PHƯỚC",
        scope: "Thẩm tra thiết kế",
        location: "Đồng Phú, Bình Phước",
        image: [
            "/images/ind-project-11.jpg"
        ]
    },
    {
        id: "ind_12",
        name: "NHÀ MÁY ẤP TRỨNG GIA CẦM DABACO BÌNH PHƯỚC",
        scope: "Thẩm tra dự án",
        location: "Đồng Phú, Bình Phước",
        image: [
            "/images/ind-project-12.jpg"
        ]
    },
    {
        id: "ind_13",
        name: "NHÀ MÁY TEAKWANG MỸ THO GIAI ĐOẠN 2",
        scope: "Thiết kế và XPXD, môi trường, PCCC và hoàn công",
        location: "Mỹ Tho, Tiền Giang",
        image: [
            "/images/ind-project-13.jpg"
        ]
    },
    // {
    //     id: "ind_14",
    //     name: "NHÀ MÁY TAEKWANG SUPER",
    //     scope: "Tư Vấn Thiết Kế",
    //     location: "Biên Hòa, Đồng Nai",
    //     image: [
    //         "/images/ind-project-14.jpg"
    //     ]
    // },
    {
        id: "ind_15",
        name: "NHÀ MÁY BÊ TÔNG - PHAN VŨ LONG AN",
        scope: "Thiết kế và XPXD, môi trường, PCCC và hoàn công",
        location: "Bến Lức – Long An",
        image: [
            "/images/ind-project-15.jpg"
        ]
    },
    {
        id: "ind_16",
        name: "NHÀ MÁY ÉP DẦU - DABACO BẮC NINH",
        scope: "Tư vấn và Thiết kế",
        location: "Tiên Du, Bắc Ninh",
        image: [
            "/images/ind-project-16.jpg"
        ]
    },
    {
        id: "ind_17",
        name: "NHÀ MÁY CHẾ BIẾN RAU QUẢ DOVECO – GIA LAI",
        scope: "Tư vấn Thiết kế",
        location: "Huyện Mang Yang, Tỉnh Gia Lai",
        image: [
            "/images/ind-project-17.jpg"
        ]
    },
    {
        id: "ind_18",
        name: "NHÀ MÁY ADIVA MỸ PHƯỚC II",
        scope: "Tư Vấn Thiết Kế",
        location: "Bình Dương",
        image: [
            "/images/ind-project-18.jpg"
        ]
    },
    // {
    //     id: "ind_19",
    //     name: "NHÀ MÁY THUỘC DA TANTEC SÀI GÒN",
    //     scope: "Tư vấn thiết kế và XPXD, môi trường, PCCC",
    //     location: "Bình Dương",
    //     image: [
    //         "/images/ind-project-19.jpg"
    //     ]
    // },
    {
        id: "ind_20",
        name: "NHÀ MÁY AICO BINH DUONG",
        scope: "Tư vấn thiết kế và XPXD, môi trường, PCCC",
        location: "Vsip II, Binh Duong",
        image: [
            "/images/ind-project-20.jpg"
        ]
    },
    {
        id: "ind_21",
        name: "LOGISTICS THÀNH CÔNG QUẢNG NINH",
        scope: "Tư vấn thiết kế",
        location: "KCN Việt Hưng – Quảng Ninh ",
        image: [
            "/images/ind-project-21.jpg"
        ]
    },
    {
        id: "ind_22",
        name: "LOGISTICS THÀNH CÔNG NINH BÌNH",
        scope: "Tư vấn thiết kế",
        location: "KCN Gián Khẩu – Ninh Bình",
        image: [
            "/images/ind-project-22.jpg"
        ]
    },
    {
        id: "ind_23",
        name: "LOGISTICS SWAN SMART LONG AN",
        scope: "Tư vấn thẩm tra thiết kế và XPXD, môi trường, PCCC",
        location: "Phú An Thạnh – Long An",
        image: [
            "/images/ind-project-23.jpg"
        ]
    },
    {
        id: "ind_24",
        name: "LOGISTICS LOGOS LONG AN 1",
        scope: "Tư vấn thẩm tra thiết kế và XPXD, môi trường, PCCC",
        location: "KCN Long Hâu 3 – Long An",
        image: [
            "/images/ind-project-24.jpg"
        ]
    }
].sort(() => Math.random() - 0.5)

export const constructions = [
    {
        id: "construction_1",
        name: " NHÀ ÔNG NGUYỄN VĂN ĐÔNG",
        location: "Châu Đức, Bà Rịa-Vũng Tàu",
        scope: "Thiết kế & Thi công",
        image: [
            "/images/construction-1a.jpg",
            "/images/construction-1b.jpg",
            "/images/construction-1c.jpg",
            "/images/construction-1d.jpg",
            "/images/construction-1e.jpg"
        ]
    },
    {
        id: "construction_2",
        name: "TÒA NHÀ  BÀ VŨ THỊ THANH 7 TẦNG",
        location: "57 Lê Văn Lương, Phường Tân Hưng, Quận 7, Tp. Hồ Chí Minh",
        scope: "Tổng thầu thiết kế & thi công",
        image: [
            "/images/construction-2.jpg",
        ]
    },
    {
        id: "construction_3",
        name: "TÒA NHÀ ÔNG NGUYỄN HOÀNG LONG– 07 TẦNG",
        location: "4/6 Nguyễn Tư Nghiêm, Phường Bình Trưng Tây, Quận 2, Tp. Hồ Chí Minh",
        scope: "Thi công xây dựng",
        image: [
            "/images/construction-3.jpg"
        ]
    },
    {
        id: "construction_4",
        name: "NHÀ MÁY CHẾ BIẾN ĐIỀU VEGETEXCO CHUẨN BRC",
        location: "H. Chơn Thành, T. Bình Phước",
        scope: "Tổng thầu Thiết kế & Thi công ",
        image: [
            "/images/construction-4a.jpg",
            "/images/construction-4b.jpg"
        ]
    },
    {
        id: "construction_5",
        name: "HẠ TẦNG KHU DÂN CƯ THANH NHẬT – THANH NHUT RESIDENTIAL AREA",
        location: "Huyện Nhà Bè, Tp Hồ Chí Minh",
        scope: "Thi công giao thông và hạ tầng kỹ thuật",
        image: [
            "/images/construction-5a.jpg",
            "/images/construction-5b.jpg",
            "/images/construction-5c.jpg",
            "/images/construction-5d.jpg"
        ]
    },
    {
        id: "construction_6",
        name: "KHÁCH SẠN NGUYÊN HƯƠNG",
        location: "40/16 đường 19B Trần Não, Phường Bình An, TP. Thủ Đức, TP. HCM.",
        scope: "Tổng thầu thiết kế & thi công",
        image: [
            "/images/construction-6a.jpg",
            "/images/construction-6b.jpg"
        ]
    },
    {
        id: "construction_7",
        name: "NHÀ Ở RIÊNG LẺ NHIÊU TỨ",
        location: "46/14 Nhiêu Tứ, Phường 7, Quận Phú Nhuận, TP.HCM.",
        scope: "Tổng thầu thiết kế & thi công",
        image: [
            "/images/construction-7a.jpg",
            "/images/construction-7b.jpg",
            "/images/construction-7c.jpg",
            "/images/construction-7d.jpg",
            "/images/construction-7e.jpg",
            "/images/construction-7f.jpg"
        ]
    }
]

export const all_projects = [...civil_projects, ...ifs_projects, ...ind_projects];

export const main_projects = all_projects.reverse().slice(0, 24);
