import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react/headless';
import styles from "./Navbar.module.scss";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

const cx = classNames.bind(styles);

function Navbar({ active = "" }) {
    const PROJECTS_MENU = [
        { name: "Công trình Dân dụng", path: "projects/civil_projects" },
        { name: "Công trình Hạ tầng kỹ thuật", path: "projects/ifs_projects" },
        { name: "Công trình Công nghiệp", path: "projects/ind_projects" }
    ]

    function render(list) {
        return list.map((item, index) => {
            return <Link className={cx('drop-item')} key={index} to={`/${item.path}`}>{item.name}</Link>
        })
    }

    return (
        <nav className={cx('wrap')}>
            <Link to="/" className={active === "home" ? cx('nav-item', 'active') : cx('nav-item')}>Trang chủ</Link>
            <Link to="/about" className={active === "about" ? cx('nav-item', 'active') : cx('nav-item')}>Giới thiệu</Link>
            <Tippy
                interactive="true"
                placement="bottom-start"
                render={() => (
                    <div className={cx("drop")} tabIndex="-1">
                        {render(PROJECTS_MENU)}
                    </div>
                )}
            >
                <Link to="/projects" className={active === "projects" ? cx('nav-item', 'active') : cx('nav-item')}>
                    <span>Dự án</span>
                    <FontAwesomeIcon className={cx('icon')} icon={faSortDown} />
                </Link>
            </Tippy>
            <Link to="/services" className={active === "services" ? cx('nav-item', 'active') : cx('nav-item')}>
                <span>Lĩnh vực hoạt động</span>
                {/* <FontAwesomeIcon className={cx('icon')} icon={faSortDown} /> */}
            </Link>
            <Link to="/contact" className={active === "contact" ? cx('nav-item', 'active') : cx('nav-item')}>Liên hệ</Link>
        </nav>
    );
}

export default Navbar;