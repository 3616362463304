import { Row, Col } from "react-bootstrap"
import Card from "~/components/Card";


function Items({ currentItems, small = false }) {
    return (
        <Row>
            {currentItems && currentItems.map(item => {
                let scope;
                if (item.scope) {
                    scope = item.scope.length > 45 ? (item.scope.slice(0, 40) + "...") : item.scope;
                } else {
                    scope = "";
                }
                return <Col md={4} sm={6} key={item.id}>
                    <Card image={item.image}
                        small={small}
                        title={item.name || ""}
                        scope={scope}
                        location={item.location || ""}
                        path={item.id ? `/projects/${item.id}` : ""} />
                </Col>
            })}
        </Row>
    );
}

export default Items;