import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Footer.module.scss";
import { faEnvelope, faMapMarkedAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

const cx = classNames.bind(styles);

function Footer() {
    const year = new Date().getFullYear();
    return (
        <div className={cx('footer')}>
            <div className={cx('logo')}>
                <img className={cx('logo-img')} src="/images/logo-card.png" alt="" />
                <h1 className={cx('logo-name')}>Công ty cổ phần tư vấn kỹ nghệ Trí Việt</h1>
            </div>
            <div className={cx('info')}>
                <h4 className={cx('info-heading')}>Thông tin liên hệ</h4>
                <div className={cx('info-list')}>
                    <div className={cx('info-item')}>
                        <FontAwesomeIcon className={cx('icon')} icon={faMapMarkedAlt} />
                        82 Hoa Lan, Phường 2, Phú Nhuận, TP. Hồ Chí Minh
                    </div>
                    <div className={cx('info-item')}>
                        <FontAwesomeIcon className={cx('icon')} icon={faPhoneAlt} />
                        +84 028.66.825.791
                    </div>
                    <div className={cx('info-item')}>
                        <FontAwesomeIcon className={cx('icon')} icon={faEnvelope} />
                        info@trivietcc.com
                    </div>
                </div>
                <div className={cx('item-text')}>
                    <a className={cx('item-link')} href="https://www.facebook.com/Tr%C3%AD-Vi%E1%BB%87t-Engineering-100218329324885" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon className={cx('icon')} icon={faFacebookF} />
                        <span>Facebook</span>
                    </a>
                </div>
            </div>

            <div className={cx('copyright')}>
                &copy; {year} Tri Viet Website, Inc.
            </div>
        </div>
    );
}

export default Footer;