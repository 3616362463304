import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import styles from "./Sidebar.module.scss";

const cx = classNames.bind(styles);

function Sidebar() {
    return (
        <div className={cx('sidebar')}>
            <div className={cx('item')}>
                <h3 className={cx('heading')}>TÌM KIẾM</h3>
                <ul className={cx('item', 'nav')}>
                    <Link to="/">
                        <li className={cx("nav-item")}>Trang chủ</li>
                    </Link>
                    <Link to="/about">
                        <li className={cx("nav-item")}>Giới thiệu</li>
                    </Link>
                    <Link to="/projects">
                        <li className={cx("nav-item")}>Dự án</li>
                    </Link>
                    <Link to="/services">
                        <li className={cx("nav-item")}>Lĩnh vực hoạt động</li>
                    </Link>
                    <Link to="/contact">
                        <li className={cx("nav-item")}>Liên hệ </li>
                    </Link>
                </ul>
            </div>

            <div className={cx('image-container', 'item')}>
                <img className={cx('img')} src="./images/poster.jpg" alt="" />
            </div>
            <div className={cx('item')}>
                <h3 className={cx('heading')}>Từ khóa</h3>
                <div className={cx('keyword-list')}>
                    <Link to="/about">
                        <div className={cx('keyword-item')}>Trí Việt</div>
                    </Link>
                    <Link to="/about">
                        <div className={cx('keyword-item')}>Tư vấn kỹ nghệ</div>
                    </Link>
                    <Link to="/about">
                        <div className={cx('keyword-item')}>Trí Việt J.S.C</div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;