import classNames from "classnames/bind";
import styles from "./Heading.module.scss";

const cx = classNames.bind(styles);

function Heading({ content }) {
    return (
        <div className={cx('heading')}>
            <h2>{content}</h2>
            <span className={cx('underline')}></span>
        </div>
    )
}

export default Heading;