import { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import classNames from "classnames/bind";
import styles from "./About.module.scss";
import Navbar from "~/components/Navbar";
import Heading from "~/components/Heading";
import Info from "./Info";

const cx = classNames.bind(styles);

const CERTIFICATES_INDEX = [1, 2, 3, 4, 5, 6, 7, 8]

function About() {
    return (
        <Fragment>
            <Navbar active="about" />
            <div className="container">
                <Row>
                    <Heading content="Thông tin công ty" />
                    <Col lg={4}>
                        <div className={cx('img-container')}>
                            <img className={cx('img')} src="./images/poster.jpg" alt="Poster" />
                        </div>
                    </Col>
                    <Col lg={8} className="px-5">
                        <Info />
                    </Col>
                </Row>
                <Row>
                    <Heading className="col-12" content="Chứng chỉ hoạt động" />
                    {CERTIFICATES_INDEX.map((item) => {
                        return <Col md={6} lg={4} key={item} className={cx('img-container')}>
                            <a href={`/images/certificate-${item}.jpg`} target="_blank" rel="noreferrer">
                                <img className={cx('img')} src={`/images/certificate-${item}.jpg`} alt="Certificate" />
                            </a>
                        </Col>
                    })
                    }
                </Row>
            </div>
        </Fragment>
    );
}

export default About;