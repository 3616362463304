import { Link } from "react-router-dom";

function NotFound404() {
    return (
        <div className="container">
            <section><h1>NỘI DUNG ĐANG ĐƯỢC CẬP NHẬT...</h1></section>
            <Link to="/" >
                <h2 className="btn-home">Quay lại trang chủ</h2>
            </Link>
        </div>
    )
}

export default NotFound404;