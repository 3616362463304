import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import styles from "./Topbar.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

const cx = classNames.bind(styles);

function Topbar() {
    return (
        <div className={cx('topbar')}>
            <div className={cx('topbar-1')}>
                <div className={cx('item')}>
                    <span className={cx('item-text')}>
                        Opening Hours:
                    </span>
                    <span className={cx('item-text')}>
                        8.00AM - 9.00PM
                    </span>
                </div>
                <div className={cx('item')}>
                    <span className={cx('item-text')}>
                        Follow Us:
                    </span>
                    <span className={cx('item-text')}>
                        <a className={cx('item-link')} rel="noreferrer" href="https://www.facebook.com/Tr%C3%AD-Vi%E1%BB%87t-Engineering-100218329324885" target="_blank">
                            <FontAwesomeIcon className={cx('icon')} icon={faFacebookF} />
                            <span>Facebook</span>
                        </a>
                    </span>
                </div>
            </div>
            <Row className={cx('topbar-2')}>
                <Col md={5}>
                    <Link to="/" className={cx('logo')}>
                        <img className={cx('logo-img')} src="/images/logo-card.png" alt="Logo" />
                        <h1 className={cx('logo-name')}>Trí Việt</h1>
                    </Link>
                </Col>
                <Col md={7}>
                    <Row className="justify-content-end">
                        <Col md={4} className={cx('info-item')}>
                            <h3 className={cx('item-title')}>VĂN PHÒNG</h3>
                            <p className={cx('item-description')}>82 Hoa Lan, Phường 2, Phú Nhuận, TP. Hồ Chí Minh</p>
                        </Col>
                        <Col md={4} className={cx('info-item')}>
                            <h3 className={cx('item-title')}>EMAIL</h3>
                            <p className={cx('item-description')}>info@trivietcc.com</p>
                        </Col>
                        <Col md={4} className={cx('info-item')}>
                            <h3 className={cx('item-title')}>ĐIỆN THOẠI</h3>
                            <p className={cx('item-description')}>02866.825.791</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default Topbar;