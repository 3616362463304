import classNames from "classnames/bind";
import { useParams } from "react-router";
import { Row, Col } from "react-bootstrap";

import styles from "./Single.module.scss";
import Heading from "~/components/Heading";
import Sidebar from "../Home/Sidebar";
import { all_projects } from "~/projects";

const cx = classNames.bind(styles);

function Single() {
    const id = useParams().id;
    const project = all_projects.find(item => item.id === id);
    return (
        <>
            <div className="container">
                <Row>
                    <Col lg={9}>
                        <Heading content={project.name} />
                        <h3><span className={cx('heading')}>Phạm vi công việc: </span> {project.scope}</h3>
                        <h3><span className={cx('heading')}>Địa điểm: </span> {project.location}</h3>
                        {project.image.map((item, index) => {
                            return <a href={item} rel="noreferrer" target="_blank" key={index} className={cx('img-container')}>
                                <img className={cx('img')} src={item} alt="Hình ảnh" />
                            </a>
                        })}
                    </Col>
                    <Col lg={3}>
                        <Sidebar />
                    </Col>
                </Row>
            </div>
        </ >
    );
}

export default Single;