import { Fragment } from "react";
import Pagination from "~/components/Pagination";
import Heading from "~/components/Heading";

const Element = ({ type, content = "Tất cả dự án", itemsPerPage = 9 }) => {
    switch (type) {
        case "civil_projects": content = "Dự án Công trình Dân dụng";
            break;
        case "ifs_projects": content = "Dự án Công trình Hạ tầng kỹ thuật";
            break;
        case "ind_projects": content = "Dự án Công trình Công nghiệp";
            break;
        case "constructions": content = "Dự án Công trình Thi công xây dựng";
            break;
        default:
            break;
    }
    return <Fragment>
        <Heading content={content} />
        <Pagination type={type} itemsPerPage={itemsPerPage} />
    </Fragment>
}

export default Element;

