import { Fragment } from "react";
import classNames from "classnames/bind";
import { Row, Col, Container } from 'react-bootstrap';

import styles from "./Home.module.scss";
import Navbar from "~/components/Navbar";
import Card from "~/components/Card";
import Slider from "./Slider";
import Heading from "~/components/Heading";
import Pagination from "~/components/Pagination";
import Sidebar from "./Sidebar";

const cx = classNames.bind(styles);

function Home() {
    const carousels = ["./images/carousel-1.jpg",
        "./images/carousel-2.jpg",
        "./images/carousel-3.jpg",
        "./images/carousel-5.jpg",
        "./images/carousel-6.jpg",
        "./images/carousel-7.jpg",
        "./images/carousel-8.jpg",
    ]
    const partners = ["./images/partner-1.jpg",
        "./images/partner-2.jpg",
        "./images/partner-3.jpg",
        "./images/partner-4.jpg",
        "./images/partner-5.jpg",
        "./images/partner-6.jpg",
        "./images/partner-7.jpg",
        "./images/partner-8.jpg",
        "./images/partner-9.jpg",
        "./images/partner-10.jpg"]
    return (
        <Fragment>
            <Navbar active="home" />
            <section className={cx('carousel')}>
                <Slider items={carousels} />
            </section>
            <Container>
                <section>
                    <Heading content="Công ty cổ phần tư vấn kỹ nghệ Trí Việt" />
                    <Row>
                        <Col md={6}>
                            <Card large path="/about" image={["./images/poster.jpg"]} title="GIỚI THIỆU CÔNG TY" content="Công Ty Cổ Phần Tư vấn Kỹ nghệ Trí Việt hoạt động theo mô hình công ty cổ phần có sự giám sát của hội đồng thành viên. Chúng tôi tự hào là đội ngũ có nhiều năm kinh nghiệm với nghề, sẵn sàng đáp ứng với mọi nhu cầu khách hàng, để hoàn thành những công trình chất lượng." />
                        </Col>
                        <Col md={6}>
                            <Card large path="/about" image={["./images/logo-card.png"]} title="TRIẾT LÝ KINH DOANH" content="VỚI PHƯƠNG CHÂM:  UY TÍN – SÁNG TẠO – HIỆU QUẢ. TRÍ VIỆT Luôn luôn mang phương châm này đến tất cả các dự án đã và đang thực hiện. TRÍ VIỆT Tự hào là một trong những nhà tư vấn UY TÍN tại Việt Nam – Một người bạn đồng hành quen thuộc của các chủ Đầu tư trong nước cũng như Quốc tế." />
                        </Col>
                    </Row>
                </section>
                <Row>
                    <Col lg={9}>
                        <Heading content="Dự án tiêu biểu" />
                        <Pagination itemsPerPage={6} type="main_projects" />
                        {/* <Heading content="Tin tức" />
                        <Pagination itemsPerPage={9} type="constructions" small /> */}
                    </Col>
                    <Col lg={3} className="d-none d-lg-block">
                        <Sidebar />
                    </Col>
                </Row>
                <section>
                    <Heading content="Đối tác công ty"></Heading>
                    <Slider items={partners} showIndicators showArrows={false} centerSlidePercentage={25} height="200px" objectFit="contain" interval={1000}/>
                </section>
            </Container>
        </Fragment>
    );
}

export default Home;